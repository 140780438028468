import * as React from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import Layout from "../components/Layout";

const IndexPage = () => {
  return (
    <Layout>
      <div className="p-8 bg-blue-4">
        <div className="prose dark:prose-dark prose-sm sm:prose lg:prose mx-auto">
          <h1>
            Indie-Log #1: Follow my journey building my SaaS app from scratch
          </h1>
          <p className="lead">
            Welcome to the very first log entry for building my SaaS app in
            public!
          </p>
          <p>
            Each week, I will write about my ideas, progress, and learnings. I
            will be completely transparent and I hope that this will become an
            interesting case study overtime for anyone who is looking to build a
            SaaS business from scratch.
          </p>
          <p>
            In this post, I'll start with describing my idea, what happened so
            far and what I want to achieve this week.
          </p>
          <p>
            I will get into much more detail in the next blog posts. However,
            for now I want to stick with the MVP approach and get started with
            blogging.
          </p>
          <h2>kukuna - Design your life</h2>
          <blockquote>
            <p>
              Understand your past. Organize your present. Shape your future.
            </p>
          </blockquote>
          <p>
            The idea I have in mind is not an idea that I came up with recently
            - in fact, I have it in mind for several years and already failed to
            implement it a couple of times. But why did I fail? From what I have
            reflected so far, it's not the complexity, but mainly for the
            following three reasons:
          </p>
          <ol>
            <li>Perfectionism & Lacking focus</li>
            <li>Missing accountability</li>
            <li>Not dedicating the time</li>
          </ol>
          <p>
            This time I want to tackle the problems by following the MVP
            approach in any step of the process, achieve higher accountability
            by documenting my journey and building in public, and setting strict
            focus times for this project.
          </p>
          <p>
            My idea is called "kukuna" and is all about designing your life. It
            will allow you to manage all the important concepts to design your
            dream life - from getting to know your life areas, finding out your
            vision and dream life, building strong habits, managing your tasks,
            and achieving your goals.
          </p>
          <p>
            It's based on several problems I have in my own life, not knowing
            where my time is going, what I really want, and how I can get there.
          </p>
          <p>The goal is to release the MVP in April 2021.</p>
          <p>
            In the next blog posts and on Twitter I will provide more
            information about the idea and what problems it aims to solve
          </p>
          <h2>What happened so far?</h2>
          <h3 className="flex flex-col items-start md:items-center md:flex-row">
            <AiOutlineCheckCircle
              className="mr-2 text-green-1"
              style={{ minWidth: "1rem", minHeight: "1rem" }}
            />
            Website is live for building in public
          </h3>
          <p>
            The MVP version of lyght.haus is live and will serve me as one of my
            main areas (next to Twitter) to document my journey.
          </p>
          <h3 className="flex flex-col items-start md:items-center md:flex-row">
            <AiOutlineCheckCircle
              className="mr-2 text-green-1"
              style={{ minWidth: "1rem", minHeight: "1rem" }}
            />
            Joined bloggingfordevs.com
          </h3>
          <p>
            To foster my accountability, even more, I joined
            bloggingfordevs.com, which is an awesome community, full of helpful
            resources and members.{" "}
          </p>
          <h3 className="flex flex-col items-start md:items-center md:flex-row">
            <AiOutlineCheckCircle
              className="mr-2 text-green-1"
              style={{ minWidth: "1rem", minHeight: "1rem" }}
            />
            Thoughts about working mode
          </h3>
          <p>
            I have several ideas about the working mode I want to follow. I will
            include methods and concepts from agile, lean startup, design
            thinking, and design sprints. I'll write a detailed blog post on how
            I will exactly approach the project.{" "}
          </p>
          <h3 className="flex flex-col items-start md:items-center md:flex-row">
            <AiOutlineCheckCircle
              className="mr-2 text-green-1"
              style={{ minWidth: "1rem", minHeight: "1rem" }}
            />
            Brainstorming ideas
          </h3>
          <p>
            Additionally to my initial idea brainstormings from the past, I
            brainstormed some new ideas. One important learning for me is that
            writing down your ideas and thoughts is crucial for activating your
            brain and unleashing your creativity. Writing = thinking
          </p>
          <h3 className="flex flex-col items-start md:items-center md:flex-row">
            <AiOutlineCheckCircle
              className="mr-2 text-green-1"
              style={{ minWidth: "1rem", minHeight: "1rem" }}
            />
            Clarification on the vision
          </h3>
          <p>
            Vision is extremely important to me. In the end, I want to improve
            something with my app, I want to solve problems and satisfy needs.
            So I thought about the long-term vision behind my idea.
          </p>
          <h3 className="flex flex-col items-start md:items-center md:flex-row">
            <AiOutlineCheckCircle
              className="mr-2 text-green-1"
              style={{ minWidth: "1rem", minHeight: "1rem" }}
            />
            Business Model Canvas & Value Proposition Canvas
          </h3>
          <p>
            I started to design the business model canvas and value proposition
            canvas.{" "}
          </p>
          <h3 className="flex flex-col items-start md:items-center md:flex-row">
            <AiOutlineCheckCircle
              className="mr-2 text-green-1"
              style={{ minWidth: "1rem", minHeight: "1rem" }}
            />
            Name brainstorming
          </h3>
          <p>
            I brainstormed a couple of names and I am pretty happy with
            "kukuna". I'm just not sure yet about the way how it will be
            written. The inspiration came from "cocoon".
          </p>
          <h3 className="flex flex-col items-start md:items-center md:flex-row">
            <AiOutlineCheckCircle
              className="mr-2 text-green-1"
              style={{ minWidth: "1rem", minHeight: "1rem" }}
            />
            Domain
          </h3>
          <p>
            This is a must-have for every side project...haha! First buy the
            domains, before you build! Otherwise, you will fail ;)
          </p>
          <h3 className="flex flex-col items-start md:items-center md:flex-row">
            <AiOutlineCheckCircle
              className="mr-2 text-green-1"
              style={{ minWidth: "1rem", minHeight: "1rem" }}
            />
            First sketches
          </h3>
          <p>
            I did some first rough sketching of my ideas and possible user
            flows.{" "}
          </p>
          <h3 className="flex flex-col items-start md:items-center md:flex-row">
            <AiOutlineCheckCircle
              className="mr-2 text-green-1"
              style={{ minWidth: "1rem", minHeight: "1rem" }}
            />
            User Story Map
          </h3>
          <p>
            I love user story maps! They are a great concept to get an overview
            of your requirements and slice them into different sprints and
            releases.{" "}
          </p>
          <h3 className="flex flex-col items-start md:items-center md:flex-row">
            <AiOutlineCheckCircle
              className="mr-2 text-green-1"
              style={{ minWidth: "1rem", minHeight: "1rem" }}
            />
            Search for design inspiration
          </h3>
          <p>
            Based on my ideas, mindfulness is an important concept to this app,
            so I was looking for some design inspiration in this direction.
            eagle.app is my tool choice to collect my design inspiration.
          </p>
          <h3 className="flex flex-col items-start md:items-center md:flex-row">
            <AiOutlineCheckCircle
              className="mr-2 text-green-1"
              style={{ minWidth: "1rem", minHeight: "1rem" }}
            />
            Thoughts about tool stack
          </h3>
          <p>
            I checked out CapacitorJS which looks interesting because I love
            using tailwind and I'd love to use it in my project. So I need to
            decide whether I want to use CapacitorJS or React Native. As a
            backend, I will probably go with Laravel, since it has by far one of
            the best communities and ecosystems out there.{" "}
          </p>
          <h3 className="flex flex-col items-start md:items-center md:flex-row">
            <AiOutlineCheckCircle
              className="mr-2 text-green-1"
              style={{ minWidth: "1rem", minHeight: "1rem" }}
            />
            First sprint goal
          </h3>
          <p>
            I set my own sprint goal which includes creating a screen that shows
            a visual overview of where the time is going.{" "}
          </p>
          <h2>What do I want to achieve this week?</h2>
          <ul>
            <li>Indie-Log update at the end of the week</li>
            <li>Refine user story map</li>
            <li>Setup tech stack</li>
            <li>Work on the first sprint goal</li>
            <li>Work on the landing page</li>
            <li>Start building in public</li>
          </ul>
          <h2>Where can I find more information?</h2>
          <p>
            Follow me on{" "}
            <a
              href="https://twitter.com/refuse2choose"
              target="_blank"
              rel="noreferrer"
            >
              Twitter
            </a>{" "}
            or check out this blog! Soon I will implement a newsletter
            integration so that you won't miss a post.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
